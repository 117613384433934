import { useNavigate } from 'react-router-dom';

export const useNavigateToRoom = () => {
  const navigate = useNavigate();

  return (roomName, isAdmin) => {
    let path = `/room/${roomName}`;

    if (isAdmin) path = `/admin/room/${roomName}`;

    navigate(path);
  };
};
