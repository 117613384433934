import CoffeeGif from './card-images/bear-coffee.gif';
import { isPlayerValid } from './player';

const NUMBERS = {
  ['NUMBER_0']: { label: '0', value: 0 },
  ['NUMBER_HALF']: { label: '1/2', value: 0.5 },
};

// 0..20
for (let i = 0; i < 21; i++) {
  NUMBERS[`NUMBER_${i}`] = { label: `${i}`, value: i };
}

// 30, 40, ..., 100
for (let i = 30; i <= 100; i += 10) {
  NUMBERS[`NUMBER_${i}`] = { label: `${i}`, value: i };
}

const EMOJIS = [
  { label: '❤️', value: 1 },
  { label: '👍', value: 2 },
  { label: '🤷', value: 3 },
  { label: '👎', value: 4 },
  { label: '😡', value: 5 },
];

export const FIBONACCI_CARDS = [
  NUMBERS.NUMBER_0,
  NUMBERS.NUMBER_HALF,
  NUMBERS.NUMBER_1,
  NUMBERS.NUMBER_2,
  NUMBERS.NUMBER_3,
  NUMBERS.NUMBER_5,
  NUMBERS.NUMBER_8,
  NUMBERS.NUMBER_13,
  NUMBERS.NUMBER_20,
  NUMBERS.NUMBER_40,
  NUMBERS.NUMBER_100,
];

export const COFFEE_CARD = { label: '☕', value: '☕', image: CoffeeGif, order: 10001, description: 'Need break' };
export const QUESTION_CARD = { label: '?', value: '?', order: 10000, description: `Need more explanation` };

export const UTILITY_CARDS = [QUESTION_CARD, COFFEE_CARD];

export const CARD_SET_SMALL = [
  NUMBERS.NUMBER_0,
  NUMBERS.NUMBER_1,
  NUMBERS.NUMBER_2,
  NUMBERS.NUMBER_3,
  NUMBERS.NUMBER_5,
  ...UTILITY_CARDS,
];
export const CARD_SET_MEDIUM = [
  NUMBERS.NUMBER_0,
  NUMBERS.NUMBER_HALF,
  NUMBERS.NUMBER_1,
  NUMBERS.NUMBER_2,
  NUMBERS.NUMBER_3,
  NUMBERS.NUMBER_5,
  NUMBERS.NUMBER_8,
  NUMBERS.NUMBER_13,
  ...UTILITY_CARDS,
];
export const CARD_SET_FULL = [...FIBONACCI_CARDS, ...UTILITY_CARDS];
export const CARD_SET_EVERYTHING = [...EMOJIS, ...Object.values(NUMBERS), ...UTILITY_CARDS];

export const getClosesCard = (cards, avgVotes) => {
  const numericCards = cards.filter((card) => !UTILITY_CARDS.includes(card));

  return numericCards.reduce((prev, curr) => {
    if (!prev) return curr;

    if (Math.abs(curr.value - avgVotes) <= Math.abs(prev.value - avgVotes)) return curr;

    return prev;
  }, null);
};

export const getAverageVote = (players) => {
  const [sum, count] = players.reduce(
    ([sum, count], player) => {
      const num = Number(player.vote);
      if (!Number.isNaN(num)) {
        sum += num;
        count++;
      }

      return [sum, count];
    },
    [0, 0]
  );

  return sum / count;
};

export const sortByValue = (cardA, cardB) => {
  const cardAPosition = cardA.order || cardA.value;
  const cardBPosition = cardB.order || cardB.value;

  return cardAPosition - cardBPosition;
};

export const calculateVotedCard = (room) => {
  if (!room?.players) return false;

  const players = Object.values(room.players).filter(isPlayerValid);

  if (!players.length) return false;
  if (!players.every((player) => player.vote !== undefined)) return false;

  const avgVote = getAverageVote(players);

  return getClosesCard(room.settings.selectedCards, avgVote);
};
