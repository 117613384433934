import React from 'react';
import styled from '@emotion/styled';
import color from 'material-color-hash';

const StyledCard = styled.div(
  ({ clickable, colorFont, colorDark, colorLight, disabled, small, mini, active, maskPercentage }) => ({
    height: small ? '7rem' : mini ? '5rem' : '10rem',
    width: small ? '4.2rem' : mini ? '3rem' : '6rem',
    fontSize: small ? '1.4rem' : mini ? '1.2rem' : '2rem',
    color: colorFont,
    background: `radial-gradient(at center top, ${colorLight}, ${colorDark})`,
    maskImage: `linear-gradient(to bottom, rgba(0,0,0,.2) ${maskPercentage}%, black ${maskPercentage}%)`,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '.25rem',
    cursor: clickable ? 'pointer' : 'default',
    borderRadius: '4px',
    userSelect: 'none',
    boxShadow: !active ? '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' : `0 0 10px 10px ${colorLight}`,
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    opacity: disabled ? 0.1 : 1,

    ':active': clickable
      ? {
          transform: 'scale(1.05)',
          boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        }
      : '',

    small: {
      fontSize: '.75rem',
      textAlign: 'center',
    },
  })
);

const Img = styled.img({
  width: '80%',
});

export const Card = ({ onClick, value, disabled, small, mini, active, description, image, label, maskPercentage }) => (
  <StyledCard
    clickable={!!onClick}
    onClick={() => onClick && onClick(value)}
    colorFont={color(value + '', 900).color}
    colorDark={color(value + '', 900).backgroundColor}
    colorLight={color(value + '', 400).backgroundColor}
    disabled={disabled}
    small={small}
    mini={mini}
    active={active}
    maskPercentage={maskPercentage && (1 - maskPercentage) * 100}
  >
    {(!image || small || mini) && label}
    {image && !(small || mini) && <Img src={image} />}
    {description && !(small || mini) && <small>{description}</small>}
  </StyledCard>
);
