import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import QRCode from 'react-qr-code';

const QRWrapper = styled.div(({ fullScreen }) => {
  const common = {
    display: 'inline-flex',
    borderRadius: '.125rem',
    padding: '.125rem',
    margin: '.25rem',
    transition: 'all ease 250ms',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    height: '1rem',
    cursor: 'pointer',
    background: 'white',
    zIndex: 1,
  };

  const fullScreenStyles = {
    position: 'fixed',
    width: '80vmin',
    height: '80vmin',
    top: 'calc(50% - 40vmin)',
    left: 'calc(50% - 40vmin)',
    padding: '.5rem',
  };

  if (fullScreen) {
    return {
      ...common,
      ...fullScreenStyles,
    };
  }

  return {
    ...common,
  };
});

const getQRCodeSize = () => {
  const height = document.documentElement.clientHeight * 0.8;
  const width = document.documentElement.clientWidth * 0.8;

  return Math.min(height, width);
};

export const QRRoomLink = ({ name }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [size, setSize] = useState(256);
  const ref = useRef(null);

  useEffect(() => {
    const onResize = () => {
      setSize(fullScreen ? getQRCodeSize() : 20);
    };

    window.addEventListener('resize', onResize);

    return () => window.addEventListener('resize', onResize);
  }, [setSize, fullScreen]);

  useEffect(() => {
    setSize(fullScreen ? getQRCodeSize() : 20);
  }, [fullScreen]);

  return (
    <QRWrapper fullScreen={fullScreen} ref={ref}>
      <QRCode
        fgColor="var(--highlight-color)"
        level="H"
        value={`${window.location.origin}/room/${name}`}
        style={{ height: '100%' }}
        onClick={() => setFullScreen(!fullScreen)}
        size={size}
      />
    </QRWrapper>
  );
};
