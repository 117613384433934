import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app/views/App';
import NoSleep from 'nosleep.js';
import { themeService } from './app/services/theme';

createRoot(document.querySelector('#app')).render(<App />);

const noSleep = new NoSleep();

themeService.init();

document.addEventListener(
  'click',
  function enableNoSleep() {
    document.removeEventListener('click', enableNoSleep, false);
    noSleep.enable();
  },
  false
);
