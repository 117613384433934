import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Input } from './ui';

const Container = styled.div(({ allowStalk }) => ({
  display: 'grid',
  gridTemplateColumns: allowStalk ? '1fr 1fr' : '1fr',
  gridGap: '.5rem',
}));

export const JoinRoom = ({ room, onJoin, onJoinAsAdmin }) => {
  const [roomName, changeRoomName] = useState(room ?? '');

  return (
    <Container allowStalk={onJoin && onJoinAsAdmin}>
      <Input
        style={{ gridColumn: '1/-1' }}
        name="room-name"
        placeholder="Room name"
        value={roomName}
        onChange={(e) => changeRoomName(e.target.value)}
      />
      {onJoin && <Button onClick={() => roomName && onJoin(roomName)}>Join room!</Button>}
      {onJoinAsAdmin && <Button onClick={() => roomName && onJoinAsAdmin(roomName)}>Stalk!</Button>}
    </Container>
  );
};
