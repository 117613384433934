import React from 'react';
import styled from '@emotion/styled';
import { useGetPlayers } from '@app/store';
import { kickPlayer, STATUS } from '../../services';
import { useGetRoomName } from '../../store';

const Player = styled.div(({ status }) => ({
  display: 'flex',

  opacity: status === STATUS.Offline ? 0.5 : 1,

  '> span': {
    marginLeft: '.5rem',
    visibility: 'hidden',
  },

  ':hover > span': {
    visibility: 'visible',
    cursor: 'pointer',
  },
}));

const getPlayerStatus = (player) => {
  if (player.status === STATUS.Skip) return '⛔';
  if (player.status === STATUS.Offline) return '🫥';
  if (![undefined, null].includes(player.vote)) return '👍';
  return '🤔';
};

export const Players = () => {
  const players = useGetPlayers();
  const roomName = useGetRoomName();

  return (
    <>
      {Object.values(players).map((player, key) => (
        <Player key={key} status={player.status}>
          {getPlayerStatus(player)} {player.name} <span onClick={() => kickPlayer(roomName, player.id)}>☠️</span>
        </Player>
      ))}
    </>
  );
};
