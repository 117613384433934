import React from 'react';
import styled from '@emotion/styled';

const Curtain = styled.div({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0,0,0,.3)'
});

const ModalCard = styled.div({
  padding: '16px',
  background: 'white',
  borderRadius: '4px',
  boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
});

export const Modal = ({ children }) => (
  <Curtain>
    <ModalCard>{children}</ModalCard>
  </Curtain>
);
