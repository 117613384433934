import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Button } from '@app/components';
import { getPlayer, setPlayer } from '@app/services';

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
});

const savePlayer = (navigate, name, roomName) => {
  if (!name) return;

  const player = {
    ...getPlayer(),
    name,
  };

  if (!player.id) player.id = crypto.randomUUID();

  setPlayer(player);
  navigate('/', { state: { id: roomName } });
};

export const PlayerInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [playerName, changePlayerName] = useState('');

  useEffect(() => {
    const player = getPlayer();
    if (player && player.name) changePlayerName(player.name);
  }, []);

  return (
    <Wrapper>
      <p>My name is</p>
      <Input
        name="player-name"
        placeholder="Player name"
        value={playerName}
        onChange={(e) => changePlayerName(e.target.value)}
      />
      <br />
      <Button onClick={() => savePlayer(navigate, playerName, location.state?.id)}>Continue</Button>
    </Wrapper>
  );
};
