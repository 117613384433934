import { LocalStorageKeys } from './localStorage';

class ThemeService {
  colorScheme = 'light';
  themeElm;

  init() {
    const storedTheme = localStorage.getItem(LocalStorageKeys.Theme);
    this.themeElm = document.querySelector('[data-theme]');

    if (!['dark', 'light'].includes(storedTheme)) {
      let matches = window.matchMedia('(prefers-color-scheme: dark)').matches;
      this.colorScheme = matches ? 'dark' : 'light';
    } else {
      this.colorScheme = storedTheme;
    }

    this.themeElm.setAttribute('data-theme', this.colorScheme);

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function (e) {
      this.colorScheme = e.matches ? 'dark' : 'light';

      this.themeElm.setAttribute('data-theme', this.colorScheme);
    });
  }

  toggleTheme() {
    this.colorScheme = this.colorScheme === 'dark' ? 'light' : 'dark';
    this.themeElm.setAttribute('data-theme', this.colorScheme);
    localStorage.setItem(LocalStorageKeys.Theme, this.colorScheme);
  }
}

export const themeService = new ThemeService();
