import React from 'react';
import { css, Global } from '@emotion/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import favicon from '../../favicon.png';
import { AdminModule } from './admin/AdminModule';
import { UserModule } from './user/UserModule';

export const App = () => {
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Global
        styles={css`
          :root[data-theme='light'] {
            --font-color: #333;
            --bg-color: #eee;
            --input-color: #fff;
            --highlight-color: #7e57c2;
            --highlight-color-transparent: #7e57c255;
            --success-color: #8bc34a;
            --link-color: var(--highlight-color);
            --link-color-transparent: var(--highlight-color-transparent);
            --button-color: var(--bg-color);
            --danger-color: #f44336;
            --card-washout-rgb: 255, 255, 255;
            --table-background: #fff;
          }

          :root[data-theme='dark'] {
            --font-color: #eee;
            --bg-color: #3a3348;
            --input-color: #53476b;
            --highlight-color: #714ab5;
            --highlight-color-transparent: #714ab577;
            --success-color: #8bc34a;
            --link-color: #9a79d2;
            --link-color-transparent: #9a79d233;
            --button-color: var(--font-color);
            --danger-color: #f44336;
            --card-washout-rgb: 80, 80, 80;
            --table-background: #302a3c;
          }

          body {
            color: var(--font-color);
            background: var(--bg-color);
            font-size: 16px;
          }

          * {
            font-family: 'Roboto', sans-serif;
          }

          a {
            text-decoration: none;
            color: var(--link-color);
          }

          .toast {
            background: var(--input-color);
            color: var(--font-color);
            border-radius: 0.25rem;
            box-shadow: 4px 4px 13px 10px rgb(0 0 0 / 12%), 6px 6px 10px 0px rgb(0 0 0 / 24%);
          }

          .toast.success {
            background: var(--success-color);
          }

          .toast.error {
            background: var(--danger-color);
          }
        `}
      />

      <BrowserRouter>
        <Routes>
          <Route path="/admin/*" element={<AdminModule />} />

          <Route path="/*" element={<UserModule />} />
        </Routes>
      </BrowserRouter>

      <Toaster
        toastOptions={{
          className: 'toast',
          error: { className: 'toast error' },
          success: { className: 'toast success' },
        }}
      />
    </>
  );
};
