import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { FeaturedCard } from './FeaturedCard';
import { getAverageVote, getClosesCard } from '@app/services';
import { ClearVotesButton } from './ClearVotesButton';
import { usePrevious } from '@app/hooks';
import { Button } from '@app/components/ui';
import { useGetVoteHistory, useGetSettings, useGetVoted } from '@app/store';
import { clearVotes, markNonVotePlayersAsSkip } from '../../services';
import { useGetPlayingPlayers, useGetRoomName } from '../../store';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '1rem',
});

const TopSection = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '1rem',
  // Three small cards + one big card
  width: '20.5rem',
  // Three small cards offset on right
  paddingRight: '14.2rem',
});

const ForceSkipButton = styled(Button)({
  marginTop: '0.5rem',
});

export const VotedCard = () => {
  const [autoClear, setAutoClear] = useState(false);
  const roomName = useGetRoomName();
  const voted = useGetVoted();
  const votedBefore = usePrevious(voted);
  const settings = useGetSettings();
  const voteHistory = useGetVoteHistory();
  const players = useGetPlayingPlayers();

  useEffect(() => {
    if (votedBefore || !voted || !settings.autoClearSeconds) return;

    setAutoClear(true);
  }, [votedBefore, voted]);

  return (
    <Container>
      <TopSection>
        {voteHistory.slice(-3).map((oldPlayerVotes, i) => (
          <FeaturedCard
            key={i}
            voted={getClosesCard(settings.selectedCards, getAverageVote(oldPlayerVotes))}
            players={oldPlayerVotes}
            small
            disabled
          />
        ))}

        <FeaturedCard voted={voted} players={players} />
      </TopSection>

      <ClearVotesButton
        resetTimeout={settings.autoClearSeconds}
        timeoutStarted={autoClear}
        setTimeoutStarted={setAutoClear}
        onClick={() => clearVotes(roomName)}
      />
      <ForceSkipButton onClick={() => markNonVotePlayersAsSkip(roomName)}>Force 🤔 players to skip</ForceSkipButton>
    </Container>
  );
};
