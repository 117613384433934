import React from 'react';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { JoinRoom, Button } from '@app/components';
import { useNavigateToRoom, joinRoom } from '@app/services';

const Container = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const Box = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '300px',
});

export const AdminHome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToRoom = useNavigateToRoom();

  const goToRoom = async (roomName, isAdmin) => {
    if (!isAdmin) await joinRoom(roomName);

    navigateToRoom(roomName, isAdmin);
  };

  return (
    <Container>
      <Box>
        <h2>Stalk existing room</h2>
        <JoinRoom room={location.state?.id} onJoinAsAdmin={(roomName) => goToRoom(roomName, true)} />
      </Box>

      <Box>
        <h2>Host new room</h2>
        <Button style={{ justifySelf: 'center' }} onClick={() => navigate('/admin/room-create')}>
          Create room
        </Button>
        <br />
        <Button style={{ justifySelf: 'center' }} onClick={() => navigate('/admin/room-manage')}>
          Manage rooms
        </Button>
      </Box>
    </Container>
  );
};
