import { useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Card } from '@app/components';
import { clearRoom, deleteRoom, refreshAllRooms } from '@app/services';
import { useGetAllRooms } from '@app/store';
import { joinRoom, useNavigateToRoom } from '@app/services';

const Table = styled.table({
  width: '100%',
  borderCollapse: 'collapse',
  background: 'var(--table-background)',
  borderRadius: '4px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.12), 0 2px 3px rgba(0,0,0,0.24)',

  'tbody tr:nth-child(2n-1)': {
    background: 'rgba(0,0,0,0.1)',
  },

  'td, th': {
    padding: '1rem',
    textAlign: 'left',
  },
});

const DateCell = ({ date }) => {
  if (!date) return 'Never';

  const formatter = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'medium' });

  return formatter.format(new Date(date));
};

const CardsCell = ({ cards }) => {
  if (!cards) return '';

  return cards.map((card) => <Card key={card.value} label={card.label} value={card.value} mini />);
};

export const ManageRooms = () => {
  const rooms = useGetAllRooms();
  useEffect(() => {
    refreshAllRooms();
  }, []);
  const navigateToRoom = useNavigateToRoom();

  const goToRoom = async (roomName, isAdmin) => {
    if (!isAdmin) await joinRoom(roomName);

    navigateToRoom(roomName, isAdmin);
  };

  if (!rooms.length) return <img src="https://media.giphy.com/media/baPIkfAo0Iv5K/giphy.gif" />;

  return (
    <Table>
      <thead>
        <tr>
          <th>Room name</th>
          <th>Players</th>
          <th>Cards</th>
          <th>Last activity</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {rooms.map((room) => (
          <tr key={room.name}>
            <td>{room.name}</td>
            <td>{Object.keys(room.players ?? {}).length ?? 0}</td>
            <td>
              <CardsCell cards={room.settings?.selectedCards} />
            </td>
            <td>
              <DateCell date={room.updatedAt} />
            </td>
            <td>
              <Button onClick={() => goToRoom(room.name, false)}>Join</Button>{' '}
              <Button onClick={() => goToRoom(room.name, true)}>Stalk</Button>
              <br />
              <br />
              <Button onClick={() => clearRoom(room.name)}>Clear</Button>{' '}
              <Button onClick={() => deleteRoom(room.name)}>Delete</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
