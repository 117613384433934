export const LocalStorageKeys = Object.freeze({
  Player: '@jetpack/poker/PLAYER',
  Theme: '@jetpack/poker/THEME',
});

export const getPlayer = () => JSON.parse(localStorage.getItem(LocalStorageKeys.Player));

export const setPlayer = (player) => localStorage.setItem(LocalStorageKeys.Player, JSON.stringify(player));

export const getPlayerId = () => {
  const player = getPlayer();

  return player && player.id;
};
