import { database } from './firebase';
import { ref, get, set } from 'firebase/database';
import { useRoomStore } from '../store';

export const refreshAllRooms = async () => {
  const roomsQuery = await get(ref(database, `rooms`));
  const rooms = roomsQuery.val();

  if (!rooms) {
    useRoomStore.getState().setRooms([]);

    return;
  }

  const roomsArray = Object.values(rooms);

  useRoomStore.getState().setRooms(roomsArray);
};

export const clearRoom = async (roomName) => {
  if (!roomName) return;

  set(ref(database, `rooms/${roomName}/players`), null);
  set(ref(database, `rooms/${roomName}/voteHistory`), null);
  set(ref(database, `rooms/${roomName}/updatedAt`), new Date().toISOString());

  await refreshAllRooms();
};

export const deleteRoom = async (roomName) => {
  if (!roomName) return;

  set(ref(database, `rooms/${roomName}`), null);

  await refreshAllRooms();
};
