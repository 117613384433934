import create from 'zustand';

export const useRoomStore = create((set) => ({
  room: {
    name: null,
    players: {},
    settings: {
      selectedCards: [],
      autoClearSeconds: null,
    },
    voteHistory: [],
  },
  rooms: [],
  isLoading: true,

  setRoom: (room) => {
    set({ room, isLoading: false });
  },

  setPlayer: (playerId, player) => {
    set((state) => ({
      room: {
        ...state.room,
        players: {
          [playerId]: player,
        },
      },
    }));
  },

  setIsLoading: (isLoading) => {
    set({ isLoading });
  },

  setRooms: (rooms) => set({ rooms }),
}));
