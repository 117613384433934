import styled from '@emotion/styled';

export const Button = styled.button({
  background: 'var(--highlight-color)',
  color: 'var(--button-color)',
  padding: '.5rem 1rem',
  borderRadius: '.25rem',
  fontSize: '0.8rem',
  border: 'none',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  outline: 'none',
  cursor: 'pointer',

  ':active': {
    transform: 'scale(1.05)',
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  },

  ':hover': {
    boxShadow: '0 2px 4px rgba(0,0,0,0.12), 0 2px 3px rgba(0,0,0,0.24)',
  },

  '&[disabled]': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
});
