import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Navigate, useParams } from 'react-router-dom';
import { Card, RoomName, Spaced } from '@app/components';
import { useGetPlayers, useGetSettings, useIsRoomLoading } from '@app/store';
import { getPlayerId, vote, toggleSkip, STATUS, listenToRoomPlayerChanges, refreshRoomSettings } from '@app/services';
import { useRedirectIfMissing } from '@app/hooks';

const Container = styled.div({
  textAlign: 'center',
});

const CardsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexFlow: 'wrap',
});

export const RoomPlay = () => {
  const params = useParams();
  const playerId = getPlayerId();
  const name = params.id;

  useEffect(() => {
    refreshRoomSettings(name).then().catch();

    return listenToRoomPlayerChanges(name);
  }, [name]);

  useRedirectIfMissing();

  const isLoading = useIsRoomLoading();
  const players = useGetPlayers();
  const settings = useGetSettings();

  const player = players[playerId];

  if (isLoading) {
    return (
      <Container>
        <h3>
          Joining room <Spaced>{name}</Spaced>...
        </h3>
      </Container>
    );
  }

  if (!player) return <Navigate to="/" state={{ id: params.id }} />;

  const playerCard = player.vote;
  const playerSkip = player.status === STATUS.Skip;

  return (
    <Container>
      <RoomName name={name} />

      <p>Pick your card:</p>
      <CardsWrapper>
        {settings?.selectedCards?.map((card, key) => (
          <Card {...card} key={key} onClick={() => vote(name, card.value)} active={playerCard === card.value} />
        ))}

        <Card label={'⛔'} onClick={() => toggleSkip(name)} active={playerSkip} description="Skip voting" />
      </CardsWrapper>
    </Container>
  );
};
