import React from 'react';
import { Card } from '@app/components/ui';
import { COFFEE_CARD, QUESTION_CARD } from '@app/services';

export const FeaturedCard = ({ players, voted, small, disabled }) => {
  const coffeePlayers = players.filter((player) => player.vote === COFFEE_CARD.value);
  const questionPlayers = players.filter((player) => player.vote === QUESTION_CARD.value);

  if (coffeePlayers && coffeePlayers.length > 0) {
    return (
      <Card
        {...COFFEE_CARD}
        description={`by ${coffeePlayers.map(({ name }) => name).join(', ')}`}
        small={small}
        disabled={disabled}
      />
    );
  }

  if (voted && questionPlayers && questionPlayers.length > 0) {
    return (
      <Card
        {...QUESTION_CARD}
        description={`from ${questionPlayers.map(({ name }) => name).join(', ')}`}
        small={small}
        disabled={disabled}
      />
    );
  }

  const votedCard = voted || QUESTION_CARD;

  return <Card {...votedCard} disabled={disabled || !voted} small={small} />;
};
