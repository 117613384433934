import { Navigate, Route, Routes, useLocation, Outlet, matchPath } from 'react-router-dom';

import { PlayerInfo, Home, RoomPlay } from './views';
import { getPlayer } from '@app/services';

const RequireUserRoute = () => {
  const location = useLocation();
  const match = matchPath('/room/:id', location.pathname);

  if (!getPlayer()) {
    return <Navigate to="player-info" state={{ id: match?.params?.id }} replace={true} />;
  }

  return <Outlet />;
};

export const UserModule = () => {
  return (
    <Routes>
      <Route path="/player-info" exact element={<PlayerInfo />} />

      <Route element={<RequireUserRoute />}>
        <Route index element={<Home />} />
        <Route path="/room/:id" exact element={<RoomPlay />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Route>
    </Routes>
  );
};
