import React from 'react';
import styled from '@emotion/styled';
import { VotedCard } from './VotedCard';
import { CardsWithVotes } from './CardsWithVotes';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Votes = () => {
  return (
    <Container>
      <VotedCard />

      <CardsWithVotes />
    </Container>
  );
};
