import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '../components/ui';

const modalContainer = document.createElement('div');
document.querySelector('body').appendChild(modalContainer);

export const openModal = data => {
  ReactDOM.render(<Modal>{data}</Modal>, modalContainer);
};
