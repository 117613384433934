import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { Button, Input, CardSets } from '@app/components';
import { useNavigateToRoom, sortByValue, createRoom } from '@app/services';

const Container = styled.div({
  textAlign: 'center',
  marginBottom: '1.5rem',
});

export const RoomCreate = () => {
  const navigateToRoom = useNavigateToRoom();

  const [selectedCards, setSelectedCards] = useState([]);
  const [autoClearSeconds, setAutoClearSeconds] = useState('');
  const [newRoomName, setNewRoomName] = useState('');

  const submit = useCallback(async () => {
    if (!selectedCards.length) return;

    const sortedSelectedCards = selectedCards.sort(sortByValue);
    const autoClearSecondsValue = autoClearSeconds || null;

    const name = await createRoom({
      selectedCards: sortedSelectedCards,
      autoClearSeconds: autoClearSecondsValue,
      name: newRoomName || null,
    });

    navigateToRoom(name, true);
  }, [selectedCards, autoClearSeconds, newRoomName]);

  return (
    <>
      <Container>
        <h2>Room Settings</h2>

        <p>
          Room name{' '}
          <Input
            name="name"
            placeholder="my-fancy-room"
            value={newRoomName}
            onChange={(e) => setNewRoomName(e.target.value)}
          />{' '}
          <small>(empty for random ID)</small>
          <br />
          <small>Using existing name will re-create the room with new settings.</small>
        </p>
      </Container>
      <CardSets setSelected={setSelectedCards} />

      <Container>
        <p>
          Automatically clear votes after{' '}
          <Input
            name="autoClearSeconds"
            placeholder="0"
            value={autoClearSeconds}
            onChange={(e) => setAutoClearSeconds(Number(e.target.value))}
          />{' '}
          seconds. <small>(0 = disabled)</small>
          <br />
          <small>
            Timer will start after all votes are in. 15 sec is good default for tasks that do not require longer
            discussions.
          </small>
        </p>
      </Container>

      <Container>
        <Button onClick={submit} disabled={!selectedCards.length}>
          Create room
        </Button>
      </Container>
    </>
  );
};
