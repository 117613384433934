import React from 'react';
import styled from '@emotion/styled';
import { Card, Tooltip } from '@app/components/ui';
import { useGetVoted, useGetPlayingPlayers } from '@app/store';

const Count = styled.div(({ disabled }) => ({
  width: '100%',
  textAlign: 'center',
  marginTop: '.5rem',
  marginBottom: '1rem',
  opacity: disabled ? 0.4 : 1,
}));

const getPlayersPerCard = (players, card) =>
  players.reduce((array, player) => {
    if (player.vote === card.value) array.push(player);
    return array;
  }, []);

const PlayerNames = ({ playersOnCard }) => playersOnCard.map((p, k) => <div key={k}>{p.name}</div>);

const EmptyCard = ({ card }) => (
  <div>
    <Card {...card} disabled small maskPercentage={0} />
    <Count disabled>0</Count>
  </div>
);

export const CardWithPlayers = ({ card }) => {
  const voted = useGetVoted();
  const players = useGetPlayingPlayers();
  const playersOnCard = getPlayersPerCard(players, card);

  if (!voted || playersOnCard.length === 0) return <EmptyCard card={card} />;

  return (
    <div>
      <Tooltip tooltip={<PlayerNames playersOnCard={playersOnCard} />}>
        <Card {...card} small maskPercentage={playersOnCard.length / players.length} />
        <Count>{playersOnCard.length}</Count>
      </Tooltip>
    </div>
  );
};
