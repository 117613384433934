import { Navigate, Route, Outlet, Routes } from 'react-router-dom';

import { AdminHome, RoomCreate, ManageRooms, AdminRoom } from './views';

const AdminRoute = () => {
  // TODO: add admin validation

  return <Outlet />;
};

export const AdminModule = () => {
  return (
    <Routes>
      <Route element={<AdminRoute />}>
        <Route index element={<AdminHome />} />
        <Route path="room-create" exact element={<RoomCreate />} />
        <Route path="room/:id" exact element={<AdminRoom />} />
        <Route path="room-manage" exact element={<ManageRooms />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Route>
    </Routes>
  );
};
