import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { Card } from '@app/components';
import { CARD_SET_SMALL, CARD_SET_MEDIUM, CARD_SET_FULL, CARD_SET_EVERYTHING } from '@app/services';

const Title = styled.p({
  textAlign: 'center',
});

const Sets = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
  gridGap: '1rem',
  justifyContent: 'center',
  alignItems: 'flex-start',
});

const Set = styled.div(({ selected }) => ({
  background: selected ? 'var(--highlight-color-transparent)' : '',
  textAlign: 'center',
  padding: '1rem',
  borderRadius: '.25rem',
}));

const Hr = styled.hr({
  background: 'rgba(103, 58, 183, .1)',
  height: '1px',
  border: 'none',
});

const CARD_SETS = [CARD_SET_SMALL, CARD_SET_MEDIUM, CARD_SET_FULL];
const CUSTOM_SET = 'custom';

export const CardSets = ({ setSelected }) => {
  const [selectedSet, setSelectedSet] = useState(CARD_SET_SMALL);
  const [selectedCards, setSelectedCards] = useState([]);

  const toggleCustomCard = useCallback(
    (card) => {
      if (selectedCards.includes(card)) return setSelectedCards(selectedCards.filter((c) => c !== card));
      setSelectedCards([...selectedCards, card]);
    },
    [selectedCards]
  );

  useEffect(() => {
    if (selectedSet === CUSTOM_SET) return setSelected(selectedCards);
    setSelected(selectedSet);
  }, [selectedSet, selectedCards]);

  return (
    <>
      <Title>Select one of card sets:</Title>
      <Sets>
        {CARD_SETS.map((set, i) => (
          <Set key={i} selected={set === selectedSet} onClick={() => setSelectedSet(set)}>
            {set.map((c) => (
              <Card {...c} key={c.label} mini disabled={set !== selectedSet} />
            ))}
          </Set>
        ))}
      </Sets>

      <Hr />

      <Title>Or select custom set:</Title>
      <Set onClick={() => setSelectedSet(CUSTOM_SET)} selected={selectedSet === CUSTOM_SET}>
        {CARD_SET_EVERYTHING.map((c) => (
          <Card {...c} key={c.label} mini disabled={!selectedCards.includes(c)} onClick={() => toggleCustomCard(c)} />
        ))}
      </Set>

      <Hr />
      <br />
    </>
  );
};
