import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { Votes, Players, RoomName } from '@app/components';
import { useIsRoomEmpty, useIsRoomLoading } from '@app/store';
import { listenToRoomChanges } from '@app/services';
import { useRedirectIfMissing } from '@app/hooks';

const Container = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const LeftSection = styled.div({
  flex: 1,
  minWidth: '280px',
  marginBottom: '2rem',
});

const RightSection = styled.div({
  paddingRight: '2rem',
});

const CenterContent = styled.div({
  textAlign: 'center',
});

const Link = styled.a({
  padding: '.75rem',
  background: 'var(--link-color-transparent)',
  borderRadius: '4px',
});

const AdminRoomEmptyState = ({ name }) => (
  <>
    <RoomName name={name} />
    <CenterContent>
      <p>Go on, invite players!</p>
      <br />
      <Link href={`/room/${encodeURI(name)}`} target="_blank">
        {window.location.origin}/room/{encodeURI(name)}
      </Link>
    </CenterContent>
  </>
);

export const AdminRoom = () => {
  const params = useParams();
  const name = params.id;

  useEffect(() => listenToRoomChanges(name), [name]);

  useRedirectIfMissing();

  const isLoading = useIsRoomLoading();
  const isRoomEmpty = useIsRoomEmpty();
  if (isRoomEmpty || isLoading) return <AdminRoomEmptyState name={name} />;

  return (
    <>
      <RoomName name={name} />
      <Container>
        <LeftSection>
          <Votes />
        </LeftSection>
        <RightSection>
          <Players />
        </RightSection>
      </Container>
    </>
  );
};
