import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';

const Popup = styled.div({
  position: 'absolute',
  borderRadius: '4px',
  padding: '.5rem',
  background: 'var(--bg-color)',
  zIndex: 1,
  boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
});

export const Tooltip = ({ children, tooltip }) => {
  const [hoverRef, isHovered, mouse] = useHover();

  return (
    <div ref={hoverRef}>
      {children}
      {isHovered && tooltip && mouse.clientY && (
        <Popup style={{ left: mouse.clientX, top: mouse.clientY + 16 }}>
          {tooltip}
        </Popup>
      )}
    </div>
  );
};

function useHover() {
  const [value, setValue] = useState(false);
  const [mouse, setMouse] = useState({});

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  const handleMouseMove = e => setMouse(e);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      node.addEventListener('mousemove', handleMouseMove);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
        node.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [ref.current]);

  return [ref, value, mouse];
}
