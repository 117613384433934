import React from 'react';
import styled from '@emotion/styled';
import { CardWithPlayers } from './CardWithPlayers';
import { useGetSettings } from '@app/store';

const Cards = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const CardsWithVotes = () => {
  const settings = useGetSettings();

  return (
    <Cards>
      {settings.selectedCards.map((card, key) => (
        <CardWithPlayers key={key} card={card} />
      ))}
    </Cards>
  );
};
