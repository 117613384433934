'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _stringHash = require('string-hash');

var _stringHash2 = _interopRequireDefault(_stringHash);

var _materialColors = require('./materialColors');

var _materialColors2 = _interopRequireDefault(_materialColors);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var availableShades = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'];
var materialWhite = 'rgba(255, 255, 255, 1)';
var materialBlack = 'rgba(0, 0, 0, 0.87)';

/* Get the whole color object, including all the shades, name and white breakpoint */
function getColorObj(text) {
  var hashedText = (0, _stringHash2.default)(text);
  var colorIndex = hashedText % _materialColors2.default.length;

  return _materialColors2.default[colorIndex];
}

/* Get a ready-to-use Style object of the Material color of a string */
function toMaterialStyle(text) {
  var shade = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;

  var shadeStr = '' + shade; // convert shade to string
  if (!availableShades.includes(shadeStr)) {
    shadeStr = '500';
  }

  var colorObj = getColorObj(text);

  return {
    backgroundColor: colorObj.shades[shadeStr],
    color: parseInt(shadeStr, 10) >= colorObj.whiteBreakpoint ? materialWhite : materialBlack,
    materialColorName: colorObj.name
  };
}

exports.default = toMaterialStyle;