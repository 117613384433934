import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Button } from '@app/components/ui';
import { usePrevious } from '@app/hooks';

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'stretch',
});

const ClearButton = styled(Button)(
  ({ noBorder }) =>
    noBorder && {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }
);

const ClearText = styled.div(({ percentage }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'var(--font-color)',
  fontSize: '.75rem',
  padding: '0 1rem',
  background: 'var(--input-color)',
  transition: 'all linear 1s',
  boxShadow: `inset ${128 * percentage}px 0 0px 0px #b39ddb`,
}));

const StopButton = styled(Button)({
  background: 'var(--danger-color)',
  boxShadow: 'none',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
});

const useTimer = (timeoutStartedBefore, timeoutStarted, resetTimeout, setTimeLeft) => {
  useEffect(() => {
    if (!timeoutStarted || timeoutStartedBefore) return;

    setTimeLeft(resetTimeout);

    const interval = setInterval(() => {
      setTimeLeft((timer) => timer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeoutStarted]);
};

export const ClearVotesButton = ({ resetTimeout, timeoutStarted, setTimeoutStarted, onClick }) => {
  const [timeLeft, setTimeLeft] = useState(resetTimeout);
  const timeoutStartedBefore = usePrevious(timeoutStarted);

  const onStopClick = useCallback(() => setTimeoutStarted(false), []);
  const onClearClick = useCallback(() => {
    onClick();
    onStopClick();
  }, []);

  useTimer(timeoutStartedBefore, timeoutStarted, resetTimeout, setTimeLeft);

  useEffect(() => {
    if (timeLeft >= 0 || !resetTimeout) return;

    onClearClick();
  }, [timeLeft]);

  return (
    <Wrapper>
      <ClearButton noBorder={timeoutStarted} onClick={onClearClick}>
        Clear votes!
      </ClearButton>

      {timeoutStarted && [
        <ClearText key={1} percentage={1 - timeLeft / resetTimeout}>
          Clearing after {timeLeft} s
        </ClearText>,
        <StopButton key={2} onClick={onStopClick}>
          Stop!
        </StopButton>,
      ]}
    </Wrapper>
  );
};
