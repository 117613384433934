import { useMemo } from 'react';
import { calculateVotedCard } from '../services';
import { isPlayerValid } from '../services/player';
import { useRoomStore } from './room.store';

export const useIsRoomEmpty = () =>
  useRoomStore(({ room }) => {
    if (!room?.players) return true;

    Object.keys(room.players).length === 0;
  });
export const useGetRoomName = () => useRoomStore(({ room: { name } }) => name);
export const useGetPlayers = () => useRoomStore(({ room }) => room?.players || {});

export const useGetPlayingPlayers = () =>
  useRoomStore(({ room }) => Object.values(room?.players ?? []).filter(isPlayerValid));

export const useGetVoteHistory = () => useRoomStore(({ room }) => room?.voteHistory ?? []);

export const useGetSettings = () => useRoomStore(({ room }) => room?.settings ?? {});

export const useGetVoted = () => useRoomStore(({ room }) => useMemo(() => calculateVotedCard(room), [room]));

export const useIsRoomLoading = () => useRoomStore(({ isLoading }) => isLoading);

export const useGetAllRooms = () => useRoomStore(({ rooms }) => rooms);
