import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { doesRoomExist } from '../services';

export const useRedirectIfMissing = () => {
  const navigate = useNavigate();
  const params = useParams();
  const name = params.id;

  useEffect(() => {
    (async () => {
      const roomExists = await doesRoomExist(name);

      if (roomExists) return;

      toast.error(`Room [${name}] does not exist. You have to create it first.`);
      navigate('/', { state: { id: name } });
    })().then();
  }, [name]);
};
