import { database } from './firebase';
import { ref, set, update, onValue, get, child, remove, onDisconnect } from 'firebase/database';
import { getPlayer, getPlayerId } from './localStorage';
import { useRoomStore } from '../store';
import { calculateVotedCard } from './cards';
import { STATUS, isPlayerValid } from './player';

export const createRoom = async ({ selectedCards, autoClearSeconds, name }) => {
  const roomName = name ?? `${Math.round(Math.random() * 100000)}`;

  const room = {
    name: roomName,
    players: {},
    settings: {
      selectedCards,
      autoClearSeconds,
    },
    voteHistory: [],
    updatedAt: new Date().toISOString(),
  };

  await set(ref(database, `rooms/${roomName}`), room);

  return roomName;
};

export const joinRoom = async (roomName) => {
  const player = getPlayer();

  player.vote = null;
  player.status = STATUS.Online;

  const roomExists = await doesRoomExist(roomName);

  if (!roomExists) return;

  update(ref(database, `rooms/${roomName}/players/${player.id}`), player);
};

export const listenToRoomChanges = (roomName) => {
  const roomRef = ref(database, `rooms/${roomName}`);

  const unsub = onValue(roomRef, (snapshot) => {
    const data = snapshot.val();

    useRoomStore.getState().setRoom(data);
  });

  return () => {
    useRoomStore.getState().setIsLoading(true);

    unsub();
  };
};

export const refreshRoomSettings = async (roomName) => {
  if (!(await doesRoomExist(roomName))) return;

  const settingsQuery = await get(ref(database, `rooms/${roomName}/settings`));
  const settings = settingsQuery.val();

  const playerId = getPlayerId();
  const playerQuery = await get(ref(database, `rooms/${roomName}/players/${playerId}`));
  const player = playerQuery.val();

  useRoomStore.getState().setRoom({ name: roomName, settings, players: { [playerId]: player } });
};

export const listenToRoomPlayerChanges = (roomName) => {
  const playerId = getPlayerId();
  const playerRef = ref(database, `rooms/${roomName}/players/${playerId}`);

  const unsub = onValue(playerRef, (snapshot) => {
    const data = snapshot.val();

    useRoomStore.getState().setPlayer(playerId, data);
  });

  doesRoomExist(roomName).then((exists) => {
    if (!exists) return;

    onDisconnect(playerRef).update({ status: STATUS.Offline });
    update(ref(database, `rooms/${roomName}/players/${playerId}`), { status: STATUS.Online });

    joinRoom(roomName).then().catch();
  });

  return () => {
    useRoomStore.getState().setIsLoading(true);

    unsub();
  };
};

export const vote = (roomName, vote) => {
  const player = getPlayer();

  update(ref(database, `rooms/${roomName}/players/${player.id}`), { vote, status: STATUS.Online });
  set(ref(database, `rooms/${roomName}/updatedAt`), new Date().toISOString());
};

export const toggleSkip = (roomName) => {
  const player = getPlayer();
  const status = useRoomStore.getState().room.players[player.id].status;

  const newStatus = status === STATUS.Skip ? STATUS.Online : STATUS.Skip;

  update(ref(database, `rooms/${roomName}/players/${player.id}`), { status: newStatus, vote: null });
  set(ref(database, `rooms/${roomName}/updatedAt`), new Date().toISOString());
};

export const doesRoomExist = async (roomName) => {
  const room = await get(child(ref(database), `rooms/${roomName}`));

  return room.exists() && room.val().name;
};

export const clearVotes = async (roomName) => {
  const room = await get(child(ref(database), `rooms/${roomName}`));
  const roomVal = room.val();

  const voted = calculateVotedCard(roomVal);

  const updates = {};

  if (voted) {
    const history = roomVal.voteHistory ?? [];

    updates[`rooms/${roomName}/voteHistory`] = [...history, Object.values(roomVal.players)];
  }

  const allPlayersWithVoteReset = Object.keys(roomVal.players).reduce((playersAll, key) => {
    playersAll[key] = { ...roomVal.players[key], vote: null };

    return playersAll;
  }, {});

  updates[`rooms/${roomName}/players`] = allPlayersWithVoteReset;

  update(ref(database), updates);
  set(ref(database, `rooms/${roomName}/updatedAt`), new Date().toISOString());
};

export const markNonVotePlayersAsSkip = async (roomName) => {
  const playersQuery = await get(child(ref(database), `rooms/${roomName}/players`));
  const players = playersQuery.val();

  Object.keys(players).forEach((key) => {
    if (players[key].vote) return;
    if (!isPlayerValid(players[key])) return;

    players[key] = { ...players[key], status: STATUS.Skip };
  });

  const updates = {
    [`rooms/${roomName}/players`]: players,
  };

  update(ref(database), updates);
  set(ref(database, `rooms/${roomName}/updatedAt`), new Date().toISOString());
};

export const kickPlayer = (roomName, playerId) => {
  remove(ref(database, `rooms/${roomName}/players/${playerId}`));
  set(ref(database, `rooms/${roomName}/updatedAt`), new Date().toISOString());
};
