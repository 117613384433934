export const STATUS = Object.freeze({
  Online: 'online',
  Offline: 'offline',
  Skip: 'skip',
});

const VALID_STATUSES = [STATUS.Online, STATUS.Offline];

export const isPlayerValid = (player) => {
  const hasValidStatus = VALID_STATUSES.includes(player.status);

  return hasValidStatus;
};
