import styled from '@emotion/styled';

export const Input = styled.input({
  color: 'var(--font-color)',
  background: 'var(--input-color)',
  padding: '.5rem 1rem',
  borderRadius: '.25rem',
  border: 'none',
  boxShadow: '0 0 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  outline: 'none',
  fontSize: '.8rem',
  '::placeholder': {
    color: 'var(--font-color)',
    opacity: 0.25,
  },
});
